._1r04idpf {
  inset: 0;
}

._18m91wug {
  overflow-y: auto;
}

._1pbybfng {
  z-index: 500;
}

._bfhk18uv {
  background-color: initial;
}

._bfhk705f {
  background-color: var(--_10avjlk);
}

._kqsw1n9t {
  position: fixed;
}

._lcxv1wug {
  pointer-events: auto;
}

._lcxvglyw {
  pointer-events: none;
}

._y44v1a7z {
  animation: 1s ease-in-out k17lnbbx;
}

._y44v1ozz {
  animation: .86s infinite kztt5h0;
}

._134c892t {
  stroke-linecap: round;
}

._1e0c116y {
  display: inline-flex;
}

._1o51q7pw {
  animation-fill-mode: forwards;
}

._1pgl2qsq {
  animation-timing-function: cubic-bezier(.4, .15, .6, .85);
}

._1snbbs82 {
  stroke-width: 1.5px;
}

._7ljx1dhp {
  stroke-dasharray: 60;
}

._8hrz1h6o {
  transform-origin: center;
}

._lswuglyw {
  fill: none;
}

._opj11kw7 {
  stroke-dashoffset: inherit;
}

._s7n4nkob {
  vertical-align: middle;
}

._tzy4idpf {
  opacity: 0;
}

@keyframes k17lnbbx {
  0% {
    opacity: 0;
    stroke-dashoffset: 60px;
    transform: rotate(50deg);
  }

  to {
    opacity: 1;
    stroke-dashoffset: 50px;
    transform: rotate(230deg);
  }
}

@keyframes kztt5h0 {
  to {
    transform: rotate(1turn);
  }
}

@media screen and (forced-colors: active) {
  ._jcxd7g3v {
    filter: grayscale();
  }

  ._1tjm1onz {
    stroke: canvastext;
  }
}

._152ttb3r {
  inset-block-start: .11em;
}

._1bsbgm0b {
  width: .9em;
}

._1e0c1nu9 {
  display: inline;
}

._1hmsglyw {
  text-decoration-line: none;
}

._4t3igm0b {
  height: .9em;
}

._ahbqzjw7 {
  margin-inline-start: .3em;
}

._s7n4jp4b {
  vertical-align: top;
}

._1053w7te:visited {
  color: var(--ds-link-visited, #5e4db2);
}

._1a3b18uv:focus {
  -webkit-text-decoration-color: initial;
  text-decoration-color: initial;
}

._4fpr8stv:focus {
  text-decoration-line: underline;
}

._5goinqa1:focus {
  text-decoration-style: solid;
}

._1bnx8stv:hover {
  text-decoration-line: underline;
}

._1bnxglyw:hover {
  text-decoration-line: none;
}

._30l314q2:hover {
  color: var(--ds-link, #0c66e4);
}

._30l315cr:hover {
  color: var(--ds-text-inverse, #fff);
}

._30l31gjq:hover {
  color: var(--ds-text-subtle, #44546f);
}

._9oik18uv:hover {
  -webkit-text-decoration-color: initial;
  text-decoration-color: initial;
}

._jf4cnqa1:hover {
  text-decoration-style: solid;
}

._n0fxw7te:visited:hover {
  color: var(--ds-link-visited, #5e4db2);
}

._1vhvg3x0:visited:active {
  color: var(--ds-link-visited-pressed, #352c63);
}

._9h8h15cr:active {
  color: var(--ds-text-inverse, #fff);
}

._9h8h16c2:active {
  color: var(--ds-link-pressed, #05c);
}

._9h8h1fxt:active {
  color: var(--ds-text, #172b4d);
}

._1hms8stv {
  text-decoration-line: underline;
}

._4bfu18uv {
  -webkit-text-decoration-color: initial;
  text-decoration-color: initial;
}

._ajmmnqa1 {
  text-decoration-style: solid;
}

._128m1bk5:focus-visible {
  outline-width: var(--ds-border-width-outline, 2px);
}

._1ah3115h:focus-visible {
  outline-offset: var(--_1203r2w);
}

._1ah31bk5:focus-visible {
  outline-offset: var(--ds-border-width-outline, 2px);
}

._mizu194a:focus-visible {
  outline-color: var(--ds-border-focused, #2684ff);
}

._ra3xnqa1:focus-visible {
  outline-style: solid;
}

@media screen and (-ms-high-contrast: active), screen and (forced-colors: active) {
  ._1cvmnqa1:focus-visible {
    outline-style: solid;
  }

  ._4davt94y:focus-visible {
    outline-width: 1px;
  }

  ._2mwq115h:focus-visible {
    outline-offset: var(--_1203r2w);
  }
}

._19itglyw {
  border: none;
}

._1q1l108i {
  --ds-elevation-surface-current: var(--ds-surface-hovered, #f1f2f4);
}

._1q1l16qz {
  --ds-elevation-surface-current: var(--ds-surface-overlay-hovered, #f1f2f4);
}

._1q1l1a0f {
  --ds-elevation-surface-current: var(--ds-surface-raised-hovered, #f1f2f4);
}

._1q1l1bhr {
  --ds-elevation-surface-current: var(--ds-surface-overlay, #fff);
}

._1q1l1ji5 {
  --ds-elevation-surface-current: var(--ds-surface-pressed, #dcdfe4);
}

._1q1lf7u5 {
  --ds-elevation-surface-current: var(--ds-surface-raised-pressed, #dcdfe4);
}

._1q1lhp5a {
  --ds-elevation-surface-current: var(--ds-surface-raised, #fff);
}

._1q1lqbzx {
  --ds-elevation-surface-current: var(--ds-surface-sunken, #f7f8f9);
}

._1q1lvq0a {
  --ds-elevation-surface-current: var(--ds-surface-overlay-pressed, #dcdfe4);
}

._1q1lvuon {
  --ds-elevation-surface-current: var(--ds-surface, #fff);
}

._1q5112x7 {
  padding-block-start: var(--ds-space-075, 6px);
}

._1q511b66 {
  padding-block-start: var(--ds-space-050, 4px);
}

._1q511ejb {
  padding-block-start: var(--ds-space-300, 24px);
}

._1q511epz {
  padding-block-start: var(--ds-space-1000, 5pc);
}

._1q511jfw {
  padding-block-start: var(--ds-space-500, 40px);
}

._1q51oahv {
  padding-block-start: var(--ds-space-600, 3pc);
}

._1q51pxbi {
  padding-block-start: var(--ds-space-200, 1pc);
}

._1q51u2gc {
  padding-block-start: var(--ds-space-100, 8px);
}

._1q51utpp {
  padding-block-start: var(--ds-space-150, 9pt);
}

._1q51v47k {
  padding-block-start: var(--ds-space-250, 20px);
}

._1q51v77o {
  padding-block-start: var(--ds-space-025, 2px);
}

._1q51xncg {
  padding-block-start: var(--ds-space-800, 4pc);
}

._1q51xy5q {
  padding-block-start: var(--ds-space-400, 2pc);
}

._1q51ze3t {
  padding-block-start: var(--ds-space-0, 0);
}

._85i512x7 {
  padding-block-end: var(--ds-space-075, 6px);
}

._85i51b66 {
  padding-block-end: var(--ds-space-050, 4px);
}

._85i51ejb {
  padding-block-end: var(--ds-space-300, 24px);
}

._85i51epz {
  padding-block-end: var(--ds-space-1000, 5pc);
}

._85i51jfw {
  padding-block-end: var(--ds-space-500, 40px);
}

._85i5oahv {
  padding-block-end: var(--ds-space-600, 3pc);
}

._85i5pxbi {
  padding-block-end: var(--ds-space-200, 1pc);
}

._85i5u2gc {
  padding-block-end: var(--ds-space-100, 8px);
}

._85i5utpp {
  padding-block-end: var(--ds-space-150, 9pt);
}

._85i5v47k {
  padding-block-end: var(--ds-space-250, 20px);
}

._85i5v77o {
  padding-block-end: var(--ds-space-025, 2px);
}

._85i5xncg {
  padding-block-end: var(--ds-space-800, 4pc);
}

._85i5xy5q {
  padding-block-end: var(--ds-space-400, 2pc);
}

._85i5ze3t {
  padding-block-end: var(--ds-space-0, 0);
}

._bfhk100f {
  background-color: var(--ds-background-accent-red-bolder-hovered, #ae2e24);
}

._bfhk108c {
  background-color: var(--ds-background-accent-teal-subtlest-hovered, #c6edfb);
}

._bfhk108i {
  background-color: var(--ds-surface-hovered, #f1f2f4);
}

._bfhk10ai {
  background-color: var(--ds-background-accent-teal-subtle-pressed, #c6edfb);
}

._bfhk10xm {
  background-color: var(--ds-blanket, #091e427d);
}

._bfhk11bn {
  background-color: var(--ds-background-accent-gray-subtlest, #f1f2f4);
}

._bfhk11dh {
  background-color: var(--ds-background-warning-pressed, #f5cd47);
}

._bfhk12e3 {
  background-color: var(--ds-background-accent-green-subtle-hovered, #7ee2b8);
}

._bfhk12j4 {
  background-color: var(--ds-background-accent-gray-subtlest-hovered, #dcdfe4);
}

._bfhk14wj {
  background-color: var(--ds-background-information-bold, #0c66e4);
}

._bfhk15ej {
  background-color: var(--ds-background-neutral-hovered, #091e4224);
}

._bfhk15mt {
  background-color: var(--ds-background-brand-boldest, #1c2b41);
}

._bfhk166n {
  background-color: var(--ds-background-neutral-subtle-hovered, #091e420f);
}

._bfhk16e6 {
  background-color: var(--ds-background-accent-blue-subtler-hovered, #85b8ff);
}

._bfhk16qz {
  background-color: var(--ds-surface-overlay-hovered, #f1f2f4);
}

._bfhk17e6 {
  background-color: var(--ds-background-accent-yellow-subtle-hovered, #e2b203);
}

._bfhk17jr {
  background-color: var(--ds-background-brand-boldest-pressed, #05c);
}

._bfhk18hz {
  background-color: var(--ds-background-selected-bold-hovered, #05c);
}

._bfhk190i {
  background-color: var(--ds-background-warning-bold, #f5cd47);
}

._bfhk19g9 {
  background-color: var(--ds-background-accent-yellow-subtlest-hovered, #f8e6a0);
}

._bfhk19ru {
  background-color: var(--ds-background-selected-pressed, #85b8ff);
}

._bfhk1a0f {
  background-color: var(--ds-surface-raised-hovered, #f1f2f4);
}

._bfhk1a17 {
  background-color: var(--ds-background-accent-teal-subtler, #c6edfb);
}

._bfhk1a2l {
  background-color: var(--ds-background-accent-gray-subtle-hovered, #b3b9c4);
}

._bfhk1amz {
  background-color: var(--ds-background-information-bold-hovered, #05c);
}

._bfhk1azz {
  background-color: var(--ds-background-accent-green-bolder-pressed, #164b35);
}

._bfhk1b17 {
  background-color: var(--ds-background-accent-lime-bolder-pressed, #37471f);
}

._bfhk1b6k {
  background-color: var(--ds-background-neutral-bold-pressed, #172b4d);
}

._bfhk1bhr {
  background-color: var(--ds-surface-overlay, #fff);
}

._bfhk1bsc {
  background-color: var(--ds-background-neutral-bold-hovered, #2c3e5d);
}

._bfhk1cwi {
  background-color: var(--ds-background-accent-blue-bolder-hovered, #05c);
}

._bfhk1d44 {
  background-color: var(--ds-background-accent-yellow-subtler-pressed, #e2b203);
}

._bfhk1d5g {
  background-color: var(--ds-background-input-hovered, #f7f8f9);
}

._bfhk1dty {
  background-color: var(--ds-background-neutral-subtle-pressed, #091e4224);
}

._bfhk1e5c {
  background-color: var(--ds-background-accent-green-bolder, #1f845a);
}

._bfhk1eds {
  background-color: var(--ds-background-accent-lime-subtler-hovered, #b3df72);
}

._bfhk1een {
  background-color: var(--ds-background-accent-magenta-subtlest-pressed, #f797d2);
}

._bfhk1f4s {
  background-color: var(--ds-background-accent-blue-subtle-hovered, #85b8ff);
}

._bfhk1f7l {
  background-color: var(--ds-background-accent-gray-subtler-hovered, #b3b9c4);
}

._bfhk1fno {
  background-color: var(--ds-background-accent-lime-subtlest-pressed, #b3df72);
}

._bfhk1fvw {
  background-color: var(--ds-background-selected-bold, #0c66e4);
}

._bfhk1g3m {
  background-color: var(--ds-background-accent-orange-subtlest, #fff3eb);
}

._bfhk1g6e {
  background-color: var(--ds-background-accent-orange-subtle, #fea362);
}

._bfhk1gii {
  background-color: var(--ds-background-inverse-subtle-pressed, #00000052);
}

._bfhk1gly {
  background-color: var(--ds-background-danger, #ffeceb);
}

._bfhk1hgz {
  background-color: var(--ds-background-accent-lime-subtler-pressed, #94c748);
}

._bfhk1i4c {
  background-color: var(--ds-background-warning-bold-hovered, #e2b203);
}

._bfhk1i6i {
  background-color: var(--ds-background-accent-red-subtlest-hovered, #ffd5d2);
}

._bfhk1io2 {
  background-color: var(--ds-background-danger-pressed, #fd9891);
}

._bfhk1j2h {
  background-color: var(--ds-background-accent-teal-subtlest-pressed, #9dd9ee);
}

._bfhk1j89 {
  background-color: var(--ds-background-accent-blue-subtle, #579dff);
}

._bfhk1j9a {
  background-color: var(--ds-background-input, #fff);
}

._bfhk1jgi {
  background-color: var(--ds-background-success-hovered, #baf3db);
}

._bfhk1ji5 {
  background-color: var(--ds-surface-pressed, #dcdfe4);
}

._bfhk1jkz {
  background-color: var(--ds-background-accent-purple-subtler, #dfd8fd);
}

._bfhk1lb4 {
  background-color: var(--ds-background-accent-gray-subtlest-pressed, #b3b9c4);
}

._bfhk1lml {
  background-color: var(--ds-background-accent-orange-subtler-hovered, #fec195);
}

._bfhk1lri {
  background-color: var(--ds-background-discovery-bold, #6e5dc6);
}

._bfhk1mi6 {
  background-color: var(--ds-background-accent-magenta-subtle, #e774bb);
}

._bfhk1nvp {
  background-color: var(--ds-background-discovery, #f3f0ff);
}

._bfhk1oew {
  background-color: var(--ds-background-accent-orange-subtle-hovered, #fec195);
}

._bfhk1ok8 {
  background-color: var(--ds-background-accent-magenta-bolder-hovered, #943d73);
}

._bfhk1ovh {
  background-color: var(--ds-background-accent-magenta-subtle-pressed, #fdd0ec);
}

._bfhk1ozg {
  background-color: var(--ds-background-accent-yellow-subtlest, #fff7d6);
}

._bfhk1p19 {
  background-color: var(--ds-background-accent-green-subtler-pressed, #4bce97);
}

._bfhk1pbw {
  background-color: var(--ds-background-accent-red-subtlest-pressed, #fd9891);
}

._bfhk1phk {
  background-color: var(--ds-background-accent-orange-subtle-pressed, #fedec8);
}

._bfhk1q4l {
  background-color: var(--ds-background-success-bold-hovered, #216e4e);
}

._bfhk1r0p {
  background-color: var(--ds-background-accent-magenta-subtler-hovered, #f797d2);
}

._bfhk1rd4 {
  background-color: var(--ds-background-warning-hovered, #f8e6a0);
}

._bfhk1rsu {
  background-color: var(--ds-background-accent-green-subtle, #4bce97);
}

._bfhk1rtt {
  background-color: var(--ds-background-accent-red-subtler, #ffd5d2);
}

._bfhk1s0z {
  background-color: var(--ds-background-information-pressed, #85b8ff);
}

._bfhk1sou {
  background-color: var(--ds-background-accent-red-subtle-hovered, #fd9891);
}

._bfhk1t05 {
  background-color: var(--ds-background-accent-gray-subtle, #8590a2);
}

._bfhk1t1b {
  background-color: var(--ds-background-accent-gray-bolder, #626f86);
}

._bfhk1tf4 {
  background-color: var(--ds-background-accent-red-subtle-pressed, #ffd5d2);
}

._bfhk1tzq {
  background-color: var(--ds-background-warning, #fff7d6);
}

._bfhk1uh7 {
  background-color: var(--ds-background-accent-blue-subtler-pressed, #579dff);
}

._bfhk1uro {
  background-color: var(--ds-background-accent-purple-bolder-pressed, #352c63);
}

._bfhk1uwk {
  background-color: var(--ds-background-brand-boldest-hovered, #09326c);
}

._bfhk1v33 {
  background-color: var(--ds-background-accent-gray-subtler, #dcdfe4);
}

._bfhk1vck {
  background-color: var(--ds-background-inverse-subtle, #00000029);
}

._bfhk1vk5 {
  background-color: var(--ds-background-accent-red-subtlest, #ffeceb);
}

._bfhk1vop {
  background-color: var(--ds-background-accent-yellow-bolder, #946f00);
}

._bfhk1vps {
  background-color: var(--ds-background-information-hovered, #cce0ff);
}

._bfhk1w72 {
  background-color: var(--ds-background-discovery-bold-hovered, #5e4db2);
}

._bfhk1wnw {
  background-color: var(--ds-background-accent-green-subtler, #baf3db);
}

._bfhk1wob {
  background-color: var(--ds-background-accent-purple-subtle-hovered, #b8acf6);
}

._bfhk1wyu {
  background-color: var(--ds-background-accent-purple-subtler-hovered, #b8acf6);
}

._bfhk1xmr {
  background-color: var(--ds-background-accent-blue-subtlest-pressed, #85b8ff);
}

._bfhk1xp7 {
  background-color: var(--ds-background-discovery-hovered, #dfd8fd);
}

._bfhk1ya0 {
  background-color: var(--ds-background-accent-orange-subtlest-pressed, #fec195);
}

._bfhk1yvi {
  background-color: var(--ds-background-danger-hovered, #ffd5d2);
}

._bfhk1z0s {
  background-color: var(--ds-background-accent-gray-subtle-pressed, #dcdfe4);
}

._bfhk2c8p {
  background-color: var(--ds-background-accent-teal-bolder, #227d9b);
}

._bfhk2ggb {
  background-color: var(--ds-background-brand-subtlest-pressed, #85b8ff);
}

._bfhk31d1 {
  background-color: var(--ds-background-accent-yellow-subtlest-pressed, #f5cd47);
}

._bfhk32kj {
  background-color: var(--ds-background-accent-yellow-bolder-pressed, #533f04);
}

._bfhk33gi {
  background-color: var(--ds-background-information, #e9f2ff);
}

._bfhk3fv2 {
  background-color: var(--ds-background-accent-yellow-subtler, #f8e6a0);
}

._bfhk3utz {
  background-color: var(--ds-background-accent-green-subtlest-hovered, #baf3db);
}

._bfhk3wxy {
  background-color: var(--ds-background-brand-subtlest, #e9f2ff);
}

._bfhk4v9p {
  background-color: var(--ds-background-accent-blue-subtler, #cce0ff);
}

._bfhk5bth {
  background-color: var(--ds-background-information-bold-pressed, #09326c);
}

._bfhk5fkp {
  background-color: var(--ds-background-accent-purple-subtler-pressed, #9f8fef);
}

._bfhk69rw {
  background-color: var(--ds-background-accent-red-subtler-pressed, #f87168);
}

._bfhk7wq0 {
  background-color: var(--ds-blanket-selected, #388bff14);
}

._bfhk7xuz {
  background-color: var(--ds-background-accent-teal-subtlest, #e7f9ff);
}

._bfhk82ra {
  background-color: var(--ds-background-accent-gray-bolder-pressed, #2c3e5d);
}

._bfhk8emd {
  background-color: var(--ds-background-success-bold, #1f845a);
}

._bfhk8h4p {
  background-color: var(--ds-background-accent-green-subtle-pressed, #baf3db);
}

._bfhk8tvm {
  background-color: var(--ds-background-accent-magenta-subtlest, #ffecf8);
}

._bfhk9pqq {
  background-color: var(--ds-background-danger-bold-pressed, #5d1f1a);
}

._bfhka5yi {
  background-color: var(--ds-background-accent-green-bolder-hovered, #216e4e);
}

._bfhkadgs {
  background-color: var(--ds-background-accent-purple-bolder-hovered, #5e4db2);
}

._bfhkavxe {
  background-color: var(--ds-background-accent-blue-subtlest, #e9f2ff);
}

._bfhkay3p {
  background-color: var(--ds-background-accent-blue-subtlest-hovered, #cce0ff);
}

._bfhkb29m {
  background-color: var(--ds-background-brand-bold, #0c66e4);
}

._bfhkbq5w {
  background-color: var(--ds-background-accent-lime-subtle, #94c748);
}

._bfhkc3uk {
  background-color: var(--ds-background-accent-magenta-bolder, #ae4787);
}

._bfhkc8cv {
  background-color: var(--ds-background-accent-blue-bolder, #0c66e4);
}

._bfhkcdhy {
  background-color: var(--ds-background-neutral-bold, #44546f);
}

._bfhkchd4 {
  background-color: var(--ds-elevation-surface-current, #fff);
}

._bfhkcrcn {
  background-color: var(--ds-background-success-bold-pressed, #164b35);
}

._bfhkd4y8 {
  background-color: var(--ds-background-accent-lime-subtlest-hovered, #d3f1a7);
}

._bfhkd8a2 {
  background-color: var(--ds-background-accent-gray-subtler-pressed, #8590a2);
}

._bfhkdh45 {
  background-color: var(--ds-background-accent-purple-subtle, #9f8fef);
}

._bfhkdlkp {
  background-color: var(--ds-background-discovery-pressed, #b8acf6);
}

._bfhke4nv {
  background-color: var(--ds-background-accent-gray-bolder-hovered, #44546f);
}

._bfhkezq1 {
  background-color: var(--ds-background-brand-subtlest-hovered, #cce0ff);
}

._bfhkf2vu {
  background-color: var(--ds-background-accent-orange-subtler, #fedec8);
}

._bfhkf767 {
  background-color: var(--ds-background-accent-orange-bolder-pressed, #702e00);
}

._bfhkf7u5 {
  background-color: var(--ds-surface-raised-pressed, #dcdfe4);
}

._bfhkfg4m {
  background-color: var(--ds-background-selected, #e9f2ff);
}

._bfhkfoww {
  background-color: var(--ds-background-accent-lime-subtle-hovered, #b3df72);
}

._bfhkg4tm {
  background-color: var(--ds-background-accent-orange-subtlest-hovered, #fedec8);
}

._bfhkg5py {
  background-color: var(--ds-background-accent-teal-subtler-hovered, #9dd9ee);
}

._bfhkgr1v {
  background-color: var(--ds-background-selected-bold-pressed, #09326c);
}

._bfhkgslw {
  background-color: var(--ds-background-accent-purple-subtlest-pressed, #b8acf6);
}

._bfhkh3tw {
  background-color: var(--ds-skeleton-subtle, #091e4208);
}

._bfhkhcrq {
  background-color: var(--ds-background-accent-blue-bolder-pressed, #09326c);
}

._bfhkhgj8 {
  background-color: var(--ds-background-accent-lime-subtle-pressed, #d3f1a7);
}

._bfhkhi52 {
  background-color: var(--ds-background-accent-orange-bolder-hovered, #a54800);
}

._bfhkhp5a {
  background-color: var(--ds-surface-raised, #fff);
}

._bfhki1yw {
  background-color: var(--ds-background-selected-hovered, #cce0ff);
}

._bfhkip91 {
  background-color: var(--ds-background-neutral-pressed, #091e424f);
}

._bfhkjgng {
  background-color: var(--ds-background-accent-orange-bolder, #c25100);
}

._bfhkkjqf {
  background-color: var(--ds-background-accent-yellow-bolder-hovered, #7f5f01);
}

._bfhkkrt9 {
  background-color: var(--ds-background-accent-red-subtle, #f87168);
}

._bfhkl3ve {
  background-color: var(--ds-background-accent-teal-bolder-hovered, #206a83);
}

._bfhklg04 {
  background-color: var(--ds-background-accent-yellow-subtler-hovered, #f5cd47);
}

._bfhkm0d5 {
  background-color: var(--ds-background-accent-purple-subtlest-hovered, #dfd8fd);
}

._bfhkm7j4 {
  background-color: var(--ds-background-neutral, #091e420f);
}

._bfhkm890 {
  background-color: var(--ds-background-accent-lime-subtlest, #efffd6);
}

._bfhkmpja {
  background-color: var(--ds-background-inverse-subtle-hovered, #0000003d);
}

._bfhkmv6i {
  background-color: var(--ds-background-success, #dcfff1);
}

._bfhkn729 {
  background-color: var(--ds-background-accent-green-subtlest, #dcfff1);
}

._bfhknzpa {
  background-color: var(--ds-background-accent-lime-bolder-hovered, #4c6b1f);
}

._bfhko0y5 {
  background-color: var(--ds-background-accent-yellow-subtle-pressed, #cf9f02);
}

._bfhkp7j4 {
  background-color: var(--ds-background-accent-purple-subtlest, #f3f0ff);
}

._bfhkprmn {
  background-color: var(--ds-background-accent-red-bolder-pressed, #5d1f1a);
}

._bfhkq7li {
  background-color: var(--ds-background-accent-teal-subtler-pressed, #6cc3e0);
}

._bfhkqbzx {
  background-color: var(--ds-surface-sunken, #f7f8f9);
}

._bfhkqctv {
  background-color: var(--ds-background-accent-blue-subtle-pressed, #cce0ff);
}

._bfhkqkzo {
  background-color: var(--ds-background-brand-bold-pressed, #09326c);
}

._bfhkr01l {
  background-color: var(--ds-background-input-pressed, #fff);
}

._bfhkr3zd {
  background-color: var(--ds-background-accent-magenta-subtlest-hovered, #fdd0ec);
}

._bfhkrb53 {
  background-color: var(--ds-background-discovery-bold-pressed, #352c63);
}

._bfhkrj9s {
  background-color: var(--ds-background-warning-bold-pressed, #cf9f02);
}

._bfhkrprw {
  background-color: var(--ds-blanket-danger, #ef5c4814);
}

._bfhkrw5d {
  background-color: var(--ds-background-accent-red-subtler-hovered, #fd9891);
}

._bfhkrwyz {
  background-color: var(--ds-background-accent-magenta-subtle-hovered, #f797d2);
}

._bfhkry5t {
  background-color: var(--ds-background-accent-yellow-subtle, #f5cd47);
}

._bfhks9hg {
  background-color: var(--ds-background-danger-bold-hovered, #ae2e24);
}

._bfhkse6l {
  background-color: var(--ds-background-accent-teal-bolder-pressed, #164555);
}

._bfhksm61 {
  background-color: var(--ds-background-neutral-subtle, #0000);
}

._bfhksyzs {
  background-color: var(--ds-background-disabled, #091e4208);
}

._bfhktde4 {
  background-color: var(--ds-background-accent-lime-bolder, #5b7f24);
}

._bfhktdv8 {
  background-color: var(--ds-background-accent-teal-subtle, #6cc3e0);
}

._bfhku1se {
  background-color: var(--ds-background-brand-bold-hovered, #05c);
}

._bfhkuibq {
  background-color: var(--ds-background-accent-red-bolder, #c9372c);
}

._bfhkung8 {
  background-color: var(--ds-background-accent-green-subtler-hovered, #7ee2b8);
}

._bfhkuxsx {
  background-color: var(--ds-background-accent-orange-subtler-pressed, #fea362);
}

._bfhkv5yf {
  background-color: var(--ds-background-accent-green-subtlest-pressed, #7ee2b8);
}

._bfhkv9ra {
  background-color: var(--ds-background-accent-magenta-subtler, #fdd0ec);
}

._bfhkvq0a {
  background-color: var(--ds-surface-overlay-pressed, #dcdfe4);
}

._bfhkvuon {
  background-color: var(--ds-surface, #fff);
}

._bfhkwaw3 {
  background-color: var(--ds-background-accent-magenta-bolder-pressed, #50253f);
}

._bfhkwz05 {
  background-color: var(--ds-background-accent-purple-subtle-pressed, #dfd8fd);
}

._bfhkxuzc {
  background-color: var(--ds-background-accent-magenta-subtler-pressed, #e774bb);
}

._bfhky4f1 {
  background-color: var(--ds-background-success-pressed, #7ee2b8);
}

._bfhkybec {
  background-color: var(--ds-background-danger-bold, #c9372c);
}

._bfhkygwo {
  background-color: var(--ds-background-accent-purple-bolder, #6e5dc6);
}

._bfhkz2ec {
  background-color: var(--ds-background-accent-lime-subtler, #d3f1a7);
}

._bfhkzh8h {
  background-color: var(--ds-background-accent-teal-subtle-hovered, #9dd9ee);
}

._bozg12x7 {
  padding-inline-start: var(--ds-space-075, 6px);
}

._bozg1ejb {
  padding-inline-start: var(--ds-space-300, 24px);
}

._bozg1epz {
  padding-inline-start: var(--ds-space-1000, 5pc);
}

._bozgoahv {
  padding-inline-start: var(--ds-space-600, 3pc);
}

._bozgpxbi {
  padding-inline-start: var(--ds-space-200, 1pc);
}

._bozgu2gc {
  padding-inline-start: var(--ds-space-100, 8px);
}

._bozgutpp {
  padding-inline-start: var(--ds-space-150, 9pt);
}

._bozgv47k {
  padding-inline-start: var(--ds-space-250, 20px);
}

._bozgv77o {
  padding-inline-start: var(--ds-space-025, 2px);
}

._bozgxncg {
  padding-inline-start: var(--ds-space-800, 4pc);
}

._bozgxy5q {
  padding-inline-start: var(--ds-space-400, 2pc);
}

._bozgze3t {
  padding-inline-start: var(--ds-space-0, 0);
}

._r06hglyw {
  appearance: none;
}

._y4ti12x7 {
  padding-inline-end: var(--ds-space-075, 6px);
}

._y4ti1ejb {
  padding-inline-end: var(--ds-space-300, 24px);
}

._y4ti1epz {
  padding-inline-end: var(--ds-space-1000, 5pc);
}

._y4ti1jfw {
  padding-inline-end: var(--ds-space-500, 40px);
}

._y4tioahv {
  padding-inline-end: var(--ds-space-600, 3pc);
}

._y4tipxbi {
  padding-inline-end: var(--ds-space-200, 1pc);
}

._y4tiu2gc {
  padding-inline-end: var(--ds-space-100, 8px);
}

._y4tiutpp {
  padding-inline-end: var(--ds-space-150, 9pt);
}

._y4tiv47k {
  padding-inline-end: var(--ds-space-250, 20px);
}

._y4tiv77o {
  padding-inline-end: var(--ds-space-025, 2px);
}

._y4tixncg {
  padding-inline-end: var(--ds-space-800, 4pc);
}

._y4tixy5q {
  padding-inline-end: var(--ds-space-400, 2pc);
}

._y4tize3t {
  padding-inline-end: var(--ds-space-0, 0);
}

._11c81doa {
  font: var(--ds-font-body-large, normal 400 1pc / 24px ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Ubuntu, system-ui, "Helvetica Neue", sans-serif);
}

._11c81oud {
  font: var(--ds-font-body, normal 400 14px / 20px ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Ubuntu, system-ui, "Helvetica Neue", sans-serif);
}

._11c81vlj {
  font: var(--ds-font-body-small, normal 400 11px / 1pc ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Ubuntu, system-ui, "Helvetica Neue", sans-serif);
}

._18u0idpf {
  margin-left: 0;
}

._19pkidpf {
  margin-top: 0;
}

._1e0ccj1k {
  display: -webkit-box;
}

._1i4qfg65 {
  overflow-wrap: anywhere;
}

._1nmz9jpi {
  word-break: break-all;
}

._2hwxidpf {
  margin-right: 0;
}

._k48p1pd9 {
  font-weight: var(--ds-font-weight-semibold, 600);
}

._k48p1wq8 {
  font-weight: var(--ds-font-weight-medium, 500);
}

._k48pi7a9 {
  font-weight: var(--ds-font-weight-regular, 400);
}

._k48pmoej {
  font-weight: var(--ds-font-weight-bold, 700);
}

._otyridpf {
  margin-bottom: 0;
}

._sudp1e54 {
  -webkit-box-orient: vertical;
}

._syaz10m5 {
  color: var(--ds-text-accent-purple, #5e4db2);
}

._syaz12v7 {
  color: var(--ds-text-accent-teal-bolder, #164555);
}

._syaz131l {
  color: var(--ds-text-subtlest, #626f86);
}

._syaz14q2 {
  color: var(--ds-link, #0c66e4);
}

._syaz15cr {
  color: var(--ds-text-inverse, #fff);
}

._syaz16c2 {
  color: var(--ds-link-pressed, #05c);
}

._syaz17qg {
  color: var(--ds-text-accent-teal, #206a83);
}

._syaz1dyx {
  color: var(--ds-text-accent-gray, #44546f);
}

._syaz1e6v {
  color: var(--ds-text-brand, #0c66e4);
}

._syaz1fxt {
  color: var(--ds-text, #172b4d);
}

._syaz1gjq {
  color: var(--ds-text-subtle, #44546f);
}

._syaz1ik3 {
  color: var(--ds-text-accent-lime-bolder, #37471f);
}

._syaz1lh4 {
  color: var(--ds-text-disabled, #091e424f);
}

._syaz1q28 {
  color: var(--ds-text-accent-orange, #a54800);
}

._syaz1qur {
  color: var(--ds-text-accent-magenta-bolder, #50253f);
}

._syaz1s8m {
  color: var(--ds-text-accent-magenta, #943d73);
}

._syaz1tco {
  color: var(--ds-text-accent-lime, #4c6b1f);
}

._syaz1tmw {
  color: var(--ds-text-danger, #ae2e24);
}

._syaz1vps {
  color: var(--ds-text-accent-orange-bolder, #702e00);
}

._syaz1x9z {
  color: var(--ds-text-accent-gray-bolder, #091e42);
}

._syaz1y78 {
  color: var(--ds-text-accent-green-bolder, #164b35);
}

._syaz2ibx {
  color: var(--ds-text-accent-purple-bolder, #352c63);
}

._syaz9lu1 {
  color: var(--ds-text-accent-red, #ae2e24);
}

._syazal3n {
  color: var(--ds-text-warning-inverse, #172b4d);
}

._syazaqb7 {
  color: var(--ds-text-selected, #0c66e4);
}

._syaze0os {
  color: var(--ds-text-information, #05c);
}

._syazekll {
  color: var(--ds-text-accent-yellow-bolder, #533f04);
}

._syazfb2s {
  color: var(--ds-text-accent-yellow, #7f5f01);
}

._syazg3x0 {
  color: var(--ds-link-visited-pressed, #352c63);
}

._syazh55r {
  color: var(--ds-text-accent-green, #216e4e);
}

._syazhwvp {
  color: var(--ds-text-accent-red-bolder, #5d1f1a);
}

._syazpnps {
  color: var(--ds-text-accent-blue, #05c);
}

._syazsc1o {
  color: var(--ds-text-accent-blue-bolder, #09326c);
}

._syazw7te {
  color: var(--ds-link-visited, #5e4db2);
}

._syazwx40 {
  color: var(--ds-text-warning, #a54800);
}

._syazy73q {
  color: var(--ds-text-success, #216e4e);
}

._syazzjem {
  color: var(--ds-text-discovery, #5e4db2);
}

._y3gnh9n0 {
  text-align: end;
}

._y3gnv2br {
  text-align: start;
}

._zg8l1m30 {
  font-style: italic;
}

._12l2r5cr {
  margin-inline-end: var(--ds-space-negative-050, -4px);
}

._1bah1y6m {
  justify-content: flex-start;
}

._1n261g80 {
  flex-wrap: wrap;
}

._ahbqr5cr {
  margin-inline-start: var(--ds-space-negative-050, -4px);
}

._bozg1b66 {
  padding-inline-start: var(--ds-space-050, 4px);
}

._y4ti1b66 {
  padding-inline-end: var(--ds-space-050, 4px);
}

._16hg1kxl._16hg1kxl {
  background-color: var(--_rryx0a);
}

._16hglslw._16hglslw {
  background-color: var(--_1jkcdu5);
}

._1g4l1np6._1g4l1np6 {
  transform: scale(.9);
}

._1w901hoq._1w901hoq {
  color: var(--_h5fhc4);
}

._1w90ln51._1w90ln51 {
  color: var(--_1hfkvbo);
}

._c6d7glyw._c6d7glyw:after {
  display: none;
}

._so1ehi7p._so1ehi7p {
  box-shadow: var(--_1t841jg);
}

._1fh11hoq._1fh11hoq:hover {
  color: var(--_h5fhc4);
}

._1fh13htb._1fh13htb:hover {
  color: var(--_ph2vs);
}

._u1mbaek3._u1mbaek3:hover {
  background-color: var(--_hz7h6s);
}

._u1mbg85j._u1mbg85j:hover {
  background-color: var(--_rkvepi);
}

._10fu1kgi._10fu1kgi:active {
  background-color: var(--_1axggjc);
}

._10fu1smf._10fu1smf:active {
  background-color: var(--_ohg1x3);
}

._1l5a17km._1l5a17km:active {
  color: var(--_1lrmnoy);
}

._1l5aln51._1l5aln51:active {
  color: var(--_1hfkvbo);
}

._18s8ze3t {
  margin: var(--ds-space-0, 0);
}

._1yt4ze3t {
  padding: var(--ds-space-0, 0);
}

._12l2u2gc {
  margin-inline-end: var(--ds-space-100, 8px);
}

._12l2x0bf {
  margin-inline-end: var(--ds-space-negative-100, -8px);
}

._19121cl4 {
  isolation: isolate;
}

._2mzuagmp {
  list-style-type: none !important;
}

._1e0c1o8l {
  display: inline-block;
}

._3se11kw7 > svg {
  fill: inherit;
}

._4t3i1tcg {
  height: 24px;
}

._4t3i1ylp {
  height: 40px;
}

._4t3i7vkz {
  height: 1pc;
}

._4t3ickbl {
  height: 3pc;
}

._4t3izwfg {
  height: 2pc;
}

._kqswh2mm {
  position: relative;
}

._lswu1xf6 {
  fill: var(--logo-fill);
}

._o5724jg8 {
  white-space: normal;
}

._syaz1vry {
  color: var(--logo-color);
}

._uiztglyw {
  -webkit-user-select: none;
  user-select: none;
}

._vc881r31 stop {
  stop-color: currentColor;
}

._vwz4kb7n {
  line-height: 1;
}

._zbji1osq > svg {
  height: 100%;
}

._1pglp3kn {
  animation-timing-function: linear;
}

._5sag12o8 {
  animation-duration: 1.5s;
}

._7hjf1yy8 {
  animation-direction: alternate;
}

._bfhkn82g {
  background-color: var(--ds-skeleton, #091e420f);
}

._j7hq586c {
  animation-name: kskr1ph;
}

._tip812c5 {
  animation-iteration-count: infinite;
}

@keyframes kskr1ph {
  0% {
    background-color: var(--_wenvhl);
  }

  to {
    background-color: var(--_1soe2er);
  }
}

._1bah1h6o {
  justify-content: center;
}

._1e0c1txw {
  display: flex;
}

._4cvr1h6o {
  align-items: center;
}

._6rthu2gc {
  margin-block-end: var(--ds-space-100, 8px);
}

._bozg1jfw {
  padding-inline-start: var(--ds-space-500, 40px);
}

._18s81efg {
  margin: var(--_129ypg0);
}

._p12f1pl1 {
  max-width: 29pc;
}

._p12fko4j {
  max-width: 19pc;
}

._y3gn1h6o {
  text-align: center;
}

._18s81t55 {
  margin: var(--_a0hk7v);
}

._1e0c1ule {
  display: block;
}

._c71l649k {
  max-height: var(--_zsxb2b);
}

._p12f163m {
  max-width: var(--_11x3y0);
}

._1bsb1ejb {
  width: var(--ds-space-300, 24px);
}

._ahbqpxbi {
  margin-inline-start: var(--ds-space-200, 1pc);
}

._11c8qk37 {
  font: var(--ds-font-body-UNSAFE_small, normal 400 9pt / 1pc ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Ubuntu, system-ui, "Helvetica Neue", sans-serif);
}

._1yt4nmcz {
  padding: var(--_a5vo3x);
}

._2rko1sit {
  border-radius: var(--ds-border-radius, 3px);
}

._152tze3t {
  inset-block-start: var(--ds-space-0, 0);
}

._18m915vq {
  overflow-y: hidden;
}

._1bto1l2s {
  text-overflow: ellipsis;
}

._1e02ze3t {
  inset-inline-start: var(--ds-space-0, 0);
}

._1i4q1hna {
  overflow-wrap: break-word;
}

._1reo15vq {
  overflow-x: hidden;
}

._bfhkpioe {
  background-color: var(--_yr7xfv);
}

._o5721q9c {
  white-space: nowrap;
}

._p12f1pna {
  max-width: 420px;
}

._p12fp3fh {
  max-width: 15pc;
}

._slp31hna {
  word-wrap: break-word;
}

._syaz1i9p {
  color: var(--_ywovvi);
}

._vchhusvi {
  box-sizing: border-box;
}
/*# sourceMappingURL=app.css.map */
